$(document).ready(function() {
	//Inject shared page content, start with header
	$(".siteheader").load("header.html");

	//Inject top navbar and footer, then inject the actual navbar content
	$(".navigation").load("navbar.html", function() {
		$(".footer").load("footer.html", function() {
			$(".navbar_content").load("navbar_content.html", function() {
				//Set the active navbar item
				//Extract the filename with regex voodoo
				var filename = /\/(\w+)\./g.exec(location.pathname);

				//Set the filename if there's no regex match because we're at site root
				if(filename === null) {
					filename = ".index";
				} else {
					filename = "." + filename[1];
				}

				$(filename).addClass("active");
			});	
		});
	});

});
